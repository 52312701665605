var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--convert-units" },
    [
      _c("the-layout-header", {
        attrs: {
          title: "Convert Units",
          "subtitle-line-one":
            "Our helpful conversion tool and ether unit reference allow you to calculate the total cost of your transactions.",
        },
      }),
      _c(
        "v-container",
        { staticClass: "my-15" },
        [
          _c(
            "v-row",
            {
              staticClass: "conver-units mx-auto mb-15",
              staticStyle: { "max-width": "1000px" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("mew-select", {
                    staticClass: "mb-2",
                    attrs: {
                      "has-filter": false,
                      items: _vm.items,
                      value: _vm.selectedLeft,
                      "normal-dropdown": "",
                    },
                    on: { input: _vm.updateCurrencyLeft },
                  }),
                  _c("mew-input", {
                    attrs: {
                      value: _vm.valueLeft,
                      type: "number",
                      label: "Amount",
                    },
                    on: { input: _vm.updateAmountLeft },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: { cols: "12", md: "2" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      style: _vm.$vuetify.breakpoint.smAndDown
                        ? "transform: rotate(90deg)"
                        : "",
                      attrs: { large: "" },
                    },
                    [_vm._v("mdi-swap-horizontal")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("mew-select", {
                    staticClass: "mb-2",
                    attrs: {
                      "has-filter": false,
                      items: _vm.items,
                      value: _vm.selectedRight,
                      "normal-dropdown": "",
                    },
                    on: { input: _vm.updateCurrencyRight },
                  }),
                  _c("mew-input", {
                    attrs: {
                      value: _vm.valueRight,
                      type: "number",
                      label: "Amount",
                    },
                    on: { input: _vm.updateAmountRight },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "mew-heading-1" }, [
            _vm._v(" " + _vm._s(_vm.$t("convertUnits.title-refference")) + " "),
          ]),
          _c("div", { staticClass: "unit-table" }, [
            _c("table", [
              _c("thead", [
                _c("tr", { staticClass: "font-weight-medium" }, [
                  _c("td", [_vm._v("Unit")]),
                  _c("td", [_vm._v("Wei")]),
                  _c("td", [_vm._v("Ether")]),
                  _c("td", [_vm._v("Alternate name")]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.etherUnitRef, function (eu) {
                  return _c("tr", { key: eu.key }, [
                    _c("td", [_vm._v(_vm._s(eu.name))]),
                    _c("td", [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$vuetify.breakpoint.mdAndUp,
                                expression: "$vuetify.breakpoint.mdAndUp",
                              },
                            ],
                            staticClass: "mr-1",
                          },
                          [_vm._v(" " + _vm._s(eu.unit1) + " = ")]
                        ),
                        _c("div", { staticClass: "unit-short" }, [
                          _vm._v(" " + _vm._s(eu.unit2) + " "),
                          _c("span", [_vm._v(" " + _vm._s(eu.unit2e))]),
                        ]),
                      ]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$vuetify.breakpoint.lgAndUp,
                                expression: "$vuetify.breakpoint.lgAndUp",
                              },
                            ],
                            staticClass: "mr-1",
                          },
                          [_vm._v(" " + _vm._s(eu.etherUnit1) + " = ")]
                        ),
                        _c("div", { staticClass: "unit-short" }, [
                          _vm._v(" " + _vm._s(eu.etherUnit2) + " "),
                          _c("span", [_vm._v(" " + _vm._s(eu.etherUnit2e))]),
                        ]),
                      ]),
                    ]),
                    _c("td", [_vm._v(_vm._s(eu.desc))]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("app-get-started"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }